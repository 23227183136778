import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

var now = moment();
const monday = mondaySdk();

const App = () => {
  const [context, setContext] = useState();
  const [actualDate, setActualDate] = useState();
  const [query, setQuery] = useState(true);
  const [audit, setAudit] = useState();
  const [tat, setTat] = useState();
  const [sales, setSales] = useState();
  const [nonSales, setNonSales] = useState();
  
  useEffect(() => {
    monday.execute("valueCreatedForUser");
    monday.listen("context", (res) => {
      monday.api(`
      {
        board: boards(ids: [3733526507]) {
          name
          id
          tags {
            id
            name
          }
          items {
            id
            name
            created_at
            column_values {
              title
              value
              text
            }
          }
        }
        lead: boards(ids: 2514925686) {
          name
          id
          items {
            name
            id
            created_at
            column_values {
              title
              value
              text
            }
          }
        }
        query: boards(ids: 2723621873) {
          name
          id
          items {
            name
            id
            created_at
            column_values {
              title
              value
              text
            }
          }
        }
        dropped: boards(ids: 2100499599) {
          name
          id
          items {
            name
            id
            created_at
            column_values {
              title
              value
              text
            }
          }
        }
        dailyBusinessActivities: boards(ids: 1897794662) {
          name
          id
          items {
            name
            id
            created_at
            column_values {
              title
              value
              text
            }
          }
        }
      }
    `, { token: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE3NTE2ODAzNiwidWlkIjozMTkwMjA3OCwiaWFkIjoiMjAyMi0wOC0xMlQxMjo0NDoyNC44MDVaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTAwMDc4OTMsInJnbiI6InVzZTEifQ.DuXDe6uS3ev53RdgVV9ln3adY64gdwXOhOiCO8m7Xag' }
      ).then((r) => {
        setContext(r.data);
      })
    });
  }, []);

  let wonBoards = []
  console.log(context)
  const salesRep = []
  function isDate(date) {
    if ((parseInt(date.split("-")[0]) == 2023) && (parseInt(date.split("-")[1]) == 1)) {
      return true;
    }
  }
  let flag = false;
  let wonData = []
  let queryData = []
  let leadData = []
  let droppedData = []

  context && context.board.map((i) => {
    i.items.map((j) => {
      j.column_values.map((k) => {
        if (isDate(j.created_at.split("T")[0])) {
          if (k.title == "Sales Rep" || k.title == "Owner") {
            if (k.text != "Marmeto Admin" && k.text!="" && k.text!="Anand Vardhan") {
              
                wonData.push({ "salesRep": k.text});
                salesRep.push(k.text)
              
            }
          }
        }
      })
    })
  })

  context && context.lead.map((i) => {
    i.items.map((j) => {
      j.column_values.map((k) => {
        if (isDate(j.created_at.split("T")[0])) {
          if (k.title == "Sales Rep" || k.title == "Owner") {
            if (k.text != "Marmeto Admin" && k.text!="" && k.text!="Anand Vardhan") {
              
                leadData.push({ "salesRep": k.text});
                salesRep.push(k.text)
              
            }
          }
        }
      })
    })
  })

  context && context.query.map((i) => {
    i.items.map((j) => {
      j.column_values.map((k) => {
        if (isDate(j.created_at.split("T")[0])) {
          if (k.title == "Sales Rep" || k.title == "Owner") {
            if (k.text != "Marmeto Admin" && k.text!="" && k.text!="Anand Vardhan") {
             
                queryData.push({ "salesRep": k.text});
                salesRep.push(k.text)
              
            }
          }
        }
      })
    })
  })

  context && context.dropped.map((i) => {
    i.items.map((j) => {
      j.column_values.map((k) => {
        if (isDate(j.created_at.split("T")[0])) {
          if (k.title == "Sales Rep" || k.title == "Owner") {
            if (k.text != "Marmeto Admin" && k.text!="" && k.text!="Anand Vardhan") {
              
                droppedData.push({ "salesRep": k.text});
                salesRep.push(k.text)
              
            }
          }
        }
      })
    })
  })

  // console.log(wonData, leadData, queryData, droppedData)
  let uniqueSalesRep = [...new Set(salesRep)];
  let queriesPicked = wonData.length + queryData.length + leadData.length + droppedData.length;
  let finalSalesRepData =[]
  uniqueSalesRep.map((e)=>{
      let sum = 0;
      let won = 0;
      let query = 0;
      let lead = 0;
      let dropped = 0;
      wonData.map((i)=>{
        if(i.salesRep == e)
        {
          sum++;
          won++;
        }
      })
      leadData.map((i)=>{
        if(i.salesRep == e)
        {
          sum++;
          lead++;
        }
      })
      queryData.map((i)=>{
        if(i.salesRep == e)
        {
          sum++;
          query++;
        }
      })
      droppedData.map((i)=>{
        if(i.salesRep == e)
        {
          sum++;
          dropped++;
        }
      })
      let percentage = Math.round(((sum * 2) / (queriesPicked)) * 100);
      let score;
      if (percentage >= 80) {
        score = 5;
      }
      else if (percentage <= 80 && percentage > 70) {
        score = 4;
      }
      else if (percentage <= 70 && percentage > 60) {
        score = 3;
      }
      else if (percentage <= 60) {
        score = 2;
      }
      finalSalesRepData.push({ "salesRep": e, "percentage": percentage, "Won" : won, "query": query, "lead" : lead, "dropped":dropped,"sum" : sum, "queries" : queriesPicked,"score":score });
  })
  console.log(finalSalesRepData)
  let nullValues = []
  let salesRepName;

  // console.log(salesRep)

  context && context.board.map((i) => {
    i.items.map((j) => {
      let salesRep, timeline, forcastedValue, actualValue, acceptedEst, cardCreation, cardLink, poc, Phone, Email, sources, industry, assessment, timeline2;
      j.column_values.map((idx) => {
        if (idx.title == 'Sales Rep') {
          salesRep = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Timeline') {
          timeline = JSON.parse(idx.value);
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Forecasted Value') {
          forcastedValue = JSON.parse(idx.value);
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Actual Deal Value' || idx.title == 'Actual Value') {
          actualValue = JSON.parse(idx.value);
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Accepted EST No.') {
          acceptedEst = JSON.parse(idx.value);
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Card Creation Date' || idx.title == 'Deal creation') {
          cardCreation = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Card Link' || idx.title == 'Card Creation Link') {
          cardLink = JSON.parse(idx.value);
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'POC' || idx.title == 'POC Name') {
          poc = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Phone') {
          Phone = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Email') {
          Email = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Sources') {
          sources = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Industry') {
          industry = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Assessment POC') {
          assessment = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Timeline 2') {
          timeline2 = idx.text;
        }
      })
      wonBoards.push({ boardName: i.name, boardId: i.id, taskName: j.name, taskId: j.id, salesRep: salesRep ? salesRep : "NA", timeline: timeline ? timeline : "NA", forcastedValue: forcastedValue ? forcastedValue : "NA", actualValue: actualValue ? actualValue : "NA", acceptedEst: acceptedEst ? acceptedEst : "NA", cardCreation: cardCreation ? cardCreation : "NA", cardLink: cardLink ? cardLink : "NA", poc: poc ? poc : "NA", Phone: Phone ? Phone : "NA", Email: Email ? Email : "NA", sources: sources ? sources : "NA", industry: industry ? industry : "NA", assessment: assessment ? assessment : "NA", timeline2: timeline2 ? timeline2 : "NA" });
    })
  });
  // console.log(wonBoards)

  let countArray = []
  wonBoards.map((e) => {
    let count = 0;
    let salesPerson;
    let titleArray = []

    Object.entries(e).map((i) => {
      let key = i[0];
      let value = i[1];
      if (value == "NA") {
        count++;
        titleArray.push(key);
      }
    })

    Object.entries(e).map((i) => {
      let key = i[0];
      let value = i[1];
      if (key == "salesRep") {
        salesPerson = value;
        countArray.push({ "salesRep": salesPerson, "count": count, "taskName": e.taskName, "title": titleArray });
      }
    })
  })


  let leadBoard = []
  let allleadBoard = []
  context && context.lead.map((i) => {
    i.items.map((j) => {
      let sales_rep, stages, status, creation_date, forecasted_value, sources, industry, poc, email, phone;

      j.column_values.map((idx) => {
        if (idx.title == 'Sales Rep') {
          sales_rep = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Stages') {
          stages = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Status') {
          status = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Creation Date') {
          creation_date = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Forecasted Value') {
          forecasted_value = idx.text;
        }
      })

      j.column_values.map((idx) => {
        if (idx.title == 'Sources') {
          sources = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Industry') {
          industry = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'POC') {
          poc = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Email') {
          email = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Phone') {
          phone = idx.text;
        }
      })
      if (creation_date && isDate(creation_date)) {
        leadBoard.push({ boardName: i.name, boardId: i.id, taskName: j.name, taskId: j.id, salesRep: sales_rep ? sales_rep : "NA", stages: stages ? stages : "NA", status: status ? status : "NA", creationDate: creation_date ? creation_date : "NA", forcastedValue: forecasted_value ? forecasted_value : "NA", sources: sources ? sources : "NA", industry: industry ? industry : "NA", poc: poc ? poc : "NA", email: email ? email : "NA", phone: phone ? phone : "NA" });
      }
      allleadBoard.push({ boardName: i.name, boardId: i.id, taskName: j.name, taskId: j.id, salesRep: sales_rep ? sales_rep : "NA", stages: stages ? stages : "NA", status: status ? status : "NA", creationDate: creation_date ? creation_date : "NA", forcastedValue: forecasted_value ? forecasted_value : "NA", sources: sources ? sources : "NA", industry: industry ? industry : "NA", poc: poc ? poc : "NA", email: email ? email : "NA", phone: phone ? phone : "NA" })
    })
  });
  // console.log(leadBoard)

  leadBoard.map((e) => {
    let count = 0;
    let salesPerson;
    let titleArray = []
    Object.entries(e).map((i) => {
      let key = i[0];
      let value = i[1];
      if (value == "NA") {
        count++;
        titleArray.push(key);
      }
    })
    Object.entries(e).map((i) => {
      let key = i[0];
      let value = i[1];
      if (key == "salesRep") {
        salesPerson = value;
        countArray.push({ "salesRep": salesPerson, "count": count, "taskName": e.taskName, "title": titleArray });
      }
    })
  })

  let queryBoard = []
  let allqueryBoard = []
  context && context.query.map((i) => {
    i.items.map((j) => {
      let creation_date, sources, poc, email, phone, industry, salesRep, initiation;

      j.column_values.map((idx) => {
        if (idx.title == 'Sales Rep') {
          salesRep = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Initiation') {
          initiation = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Creation Date') {
          creation_date = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Email') {
          email = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Phone') {
          phone = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'POC') {
          poc = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Industry') {
          industry = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Sources') {
          sources = idx.text;
        }
      })
      if (creation_date && isDate(creation_date)) {
        queryBoard.push({ boardName: i.name, boardId: i.id, taskName: j.name, taskId: j.id, salesRep: salesRep ? salesRep : "NA", initiation: initiation ? initiation : "NA", creationDate: creation_date ? creation_date : "NA", email: email ? email : "NA", phone: phone ? phone : "NA", poc: poc ? poc : "NA", industry: industry ? industry : "NA", sources: sources ? sources : "NA" });
      }
      allqueryBoard.push({ boardName: i.name, boardId: i.id, taskName: j.name, taskId: j.id, salesRep: salesRep ? salesRep : "NA", initiation: initiation ? initiation : "NA", creationDate: creation_date ? creation_date : "NA", email: email ? email : "NA", phone: phone ? phone : "NA", poc: poc ? poc : "NA", industry: industry ? industry : "NA", sources: sources ? sources : "NA" });
    })
  });

  queryBoard.map((e) => {
    let count = 0;
    let salesPerson;
    let titleArray = []
    Object.entries(e).map((i) => {
      let key = i[0];
      let value = i[1];
      if (value == "NA") {
        count++;
        titleArray.push(key);
      }
    })

    Object.entries(e).map((i) => {
      let key = i[0];
      let value = i[1];
      if (key == "salesRep") {
        salesPerson = value;
        countArray.push({ "salesRep": salesPerson, "count": count, "taskName": e.taskName, "title": titleArray });
      }
    })
  })

  let droppedBoards = []
  let alldroppedBoards = []
  context && context.dropped.map((i) => {
    i.items.map((j) => {
      let creation_date, salesRep, poc, email, phone, closureDate, sources, industry, initiation, reason, addToContacts;

      j.column_values.map((idx) => {
        if (idx.title == 'Sales Rep') {
          salesRep = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Creation Date') {
          creation_date = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Email') {
          email = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Phone') {
          phone = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'POC') {
          poc = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Industry') {
          industry = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Sources') {
          sources = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Closure Date') {
          closureDate = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Initiation') {
          initiation = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Reason') {
          reason = idx.text;
        }
      })
      j.column_values.map((idx) => {
        if (idx.title == 'Add to Contacts') {
          addToContacts = idx.text;
        }
      })
      if (creation_date && isDate(creation_date)) {
        droppedBoards.push({ boardName: i.name, boardId: i.id, taskName: j.name, taskId: j.id, salesRep: salesRep ? salesRep : "NA", initiation: initiation ? initiation : "NA", creationDate: creation_date ? creation_date : "NA", email: email ? email : "NA", phone: phone ? phone : "NA", poc: poc ? poc : "NA", industry: industry ? industry : "NA", sources: sources ? sources : "NA", reason: reason ? reason : "NA", addToContacts: addToContacts ? addToContacts : "NA", closureDate: closureDate ? closureDate : "NA" });
      }
      alldroppedBoards.push({ boardName: i.name, boardId: i.id, taskName: j.name, taskId: j.id, salesRep: salesRep ? salesRep : "NA", initiation: initiation ? initiation : "NA", creationDate: creation_date ? creation_date : "NA", email: email ? email : "NA", phone: phone ? phone : "NA", poc: poc ? poc : "NA", industry: industry ? industry : "NA", sources: sources ? sources : "NA", reason: reason ? reason : "NA", addToContacts: addToContacts ? addToContacts : "NA", closureDate: closureDate ? closureDate : "NA" })
    })
  });
  // console.log(droppedBoards)
  droppedBoards.map((e) => {
    let count = 0;
    let salesPerson;
    let titleArray = []
    Object.entries(e).map((i) => {
      let key = i[0];
      let value = i[1];
      if (value == "NA") {
        count++;
        titleArray.push(key);
      }
    })

    Object.entries(e).map((i) => {
      let key = i[0];
      let value = i[1];
      if (key == "salesRep") {
        salesPerson = value;
        countArray.push({ "salesRep": salesPerson, "count": count, "taskName": e.taskName, "title": titleArray });
      }
    })
  })

  // console.log(countArray)
  // console.log(allqueryBoard, allleadBoard, alldroppedBoards)

  let finalCountArray = []
  let uniqueFinalCountArray = [...new Set(countArray.map((item) => item.salesRep))];
  uniqueFinalCountArray.map((ele) => {
    let count = 0;
    let score = 0;
    let identifier = ""
    countArray.map((e) => {
      if (ele == e.salesRep) {
        count += e.count;
        e.title.map((i) => {
          identifier = identifier + (e.taskName + " : " + i + "<br>");
        })
      }
    })
    if (count == 0) {
      score = 5;
    }
    else if (count == 1 || count == 2) {
      score = 4;
    }
    else if (count == 3) {
      score = 3;
    }
    else if (count > 3) {
      score = 2;
    }
    if (ele != "NA" && ele != "Marmeto Admin") finalCountArray.push({ "salesRep": ele, "count": count, "score": score, "identifier": identifier });
  })
  // console.log(finalCountArray)

  


  // console.log(finalSalesRepData)

  // var day = moment(date)
  // console.log(day.month())

  // console.log(actualDate)
  let nowDate = moment(actualDate)
  let validDays = []
  function tatDate(date) {
    let duration = moment.duration(nowDate.diff(date));
    if (parseInt(date._i.split("-")[0]) >= 2023 && (parseInt(date._i.split("-")[1]) == 1 || parseInt(date._i.split("-")[1]) == 1)) {
      if (duration >= 7 * 24 * 60 * 60 * 1000) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  let finalSalesRepTAT = []
  // alldroppedBoards.map((i) => {
  //   if (i.salesRep != "NA" && i.salesRep != "" && i.salesRep != "Marmeto Admin") {
  //     finalSalesRepTAT.push(i.salesRep)
  //   }
  // })
  allqueryBoard.map((i) => {
    if (i.salesRep != "NA" && i.salesRep != "" && i.salesRep != "Marmeto Admin") {
      finalSalesRepTAT.push(i.salesRep)
    }
  })
  let finalUniqueSalesRepTAT = [...new Set(finalSalesRepTAT)];
  let finalInitCount = []
  finalUniqueSalesRepTAT.map((e) => {
    let count = 0
    let identifier = ""
    allqueryBoard.map((i) => {
      let flag1 = false;
      let flag2 = false;
      let flag3 = false;
      if (e == i.salesRep) {
        flag1 = true;
      }
      if (tatDate(moment(i.creationDate))) {
        flag2 = true;
      }
      if (flag1 && flag2) {
        if (i.initiation != "Done") {
          flag3 = true;
          count++;
        }
      }
      if (flag1 && flag2 && flag3) {
        identifier += i.boardName + " => " + i.taskName + " | ";
      }
    })
    finalInitCount.push({ "salesRep": e, "count": count, "identifier": identifier })
  })
  let finalScoreTAT = []
  // console.log(finalInitCount)
  finalUniqueSalesRepTAT.map((e) => {
    if(e!="Saurav K")
    {
    let count = 0;
    let identifier = "";
    finalInitCount.map((j) => {
      if (e == j.salesRep) {
        count += j.count;
        identifier += " " + j.identifier;
      }
    })
    let score;
    if (count == 0) {
      score = 5;
    }
    else if (count == 1) {
      score = 4;
    }
    else if (count == 2) {
      score = 3;
    }
    else if (count >= 3) {
      score = 2;
    }
    finalScoreTAT.push({ "salesRep": e, "count": count, "score": score, "identifier": identifier })
    }
  })

  // console.log(finalScoreTAT)

  let dailyBoard = []
  let dailyBoardSalesRep = []
  let dailySalesTimeAvg = []
  let dates = []
  context && context.dailyBusinessActivities.map((i) => {
    i.items.map((j) => {
      let timeTracking, salesRep, date;
      let flag1 = false;
      let flag2 = false;
      j.column_values.map((e) => {
        if (e.title == "Date of Entry") {
          if (parseInt(e.text.split("-")[0]) == 2023 && parseInt(e.text.split("-")[1]) == 1) {
            flag1 = true;
            date = e.text;
            dates.push(date)
          }
        }
      })
      j.column_values.map((e) => {
        if (e.title == "Type") {
          if (e.text == "Account") {
            flag2 = true;
          }
        }
      })
      if (flag1 && flag2) {
        j.column_values.map((e) => {
          if (e.title == "Time Tracking") {
            if (e.text != undefined) {
              timeTracking = moment.duration(e.text).asMinutes();
            }
          }
        })
        j.column_values.map((e) => {
          if (e.title == "Sales Rep") {
            salesRep = e.text;
            if (i.salesRep != "NA" || i.salesRep != "" || i.salesRep != "Marmeto Admin") {
              dailyBoardSalesRep.push(salesRep)
            }
          }
        })
        dailySalesTimeAvg.push({ "salesRep": salesRep, "timeTracking": timeTracking, "date": date })
      }
    })
  })
  // console.log(dailySalesTimeAvg)
  let uniqueDates = [...new Set(dates)];
  let uniqueDailyBoardSalesRep = [...new Set(dailyBoardSalesRep)]
  let finalDailySalesTime = []
  // console.log(uniqueDates)
  uniqueDailyBoardSalesRep.map((e) => {
    let temp = []
    let tempDate = []
    dailySalesTimeAvg.map((i) => {
      if (e == i.salesRep) {
        temp.push({ 'date': i.date, "salesRep": e, "time": i.timeTracking })
        tempDate.push(i.date)
      }
    })
    let uniqTempDate = [...new Set(tempDate)];
    // console.log(uniqTempDate)
    let arr = []
    uniqTempDate.map((j) => {
      let totalTime = 0;
      temp.map((i) => {
        if (j == i.date) {
          // console.log("hi")
          totalTime += parseInt(i.time);
        }
      })
      // totalTime = Math.round(((totalTime / 60) / uniqTempDate.length) * 10) / 10;
      // console.log(totalTime)
      arr.push({ "date": j, "time": totalTime, "salesRep": e })
    })
    let avgTime = 0;
    arr.map((item) => {
      avgTime += item.time;
    })
    avgTime = Math.round(((avgTime / 60) / arr.length) * 10) / 10;
    let score;
    if (avgTime <= 1.5) {
      score = 5;
    }
    else if (avgTime <= 2 && avgTime > 1.5) {
      score = 4;
    }
    else if (avgTime <= 2.5 && avgTime > 2) {
      score = 3;
    }
    else if (avgTime > 2.5) {
      score = 2;
    }
    finalDailySalesTime.push({ "salesRep": e, "totalTime": avgTime, "score": score })
  })
  // console.log(finalDailySalesTime)

  let noSalesdailyBoard = []
  let noSalesDailyBoardSalesRep = []
  let noSalesDailySalesTimeAvg = []
  let noSalesDailydates = []
  context && context.dailyBusinessActivities.map((i) => {
    i.items.map((j) => {
      let timeTracking, salesRep, date;
      let flag1 = false;
      let flag2 = false;
      j.column_values.map((e) => {
        if (e.title == "Date of Entry") {
          if (parseInt(e.text.split("-")[0]) == 2023 && parseInt(e.text.split("-")[1]) == 1) {
            flag1 = true;
            date = e.text;
            noSalesDailydates.push(date)
          }
        }
      })
      j.column_values.map((e) => {
        if (e.title == "Type") {
          if (e.text != "Account") {
            flag2 = true;
          }
        }
      })
      if (flag1 && flag2) {
        j.column_values.map((e) => {
          if (e.title == "Time Tracking") {
            if (e.text != undefined) {
              timeTracking = moment.duration(e.text).asMinutes();
            }
          }
        })
        j.column_values.map((e) => {
          if (e.title == "Sales Rep") {
            salesRep = e.text;
            if (i.salesRep != "NA" || i.salesRep != "" || i.salesRep != "Marmeto Admin") {
              noSalesDailyBoardSalesRep.push(salesRep)
            }
          }
        })
        noSalesDailySalesTimeAvg.push({ "salesRep": salesRep, "timeTracking": timeTracking, "date": date })
      }
    })
  })



  let noSalesUniqueDailyBoardSalesRep = [...new Set(noSalesDailyBoardSalesRep)]
  let finalNoSalesDailySalesTime = []
  // console.log(uniqueDates)
  noSalesUniqueDailyBoardSalesRep.map((e) => {
    let temp = []
    let tempDate = []
    noSalesDailySalesTimeAvg.map((i) => {
      if (e == i.salesRep) {
        temp.push({ 'date': i.date, "salesRep": e, "time": i.timeTracking })
        tempDate.push(i.date)
      }
    })
    let uniqTempDate = [...new Set(tempDate)];
    // console.log(uniqTempDate)
    let arr = []
    uniqTempDate.map((j) => {
      let totalTime = 0;
      temp.map((i) => {
        if (j == i.date) {
          // console.log("hi")
          totalTime += parseInt(i.time);
        }
      })
      // totalTime = Math.round(((totalTime / 60) / uniqTempDate.length) * 10) / 10;
      // console.log(totalTime)
      arr.push({ "date": j, "time": totalTime, "salesRep": e })
    })
    // console.log(arr)
    let avgTime = 0;
    arr.map((item) => {
      avgTime += item.time;
    })
    avgTime = Math.round(((avgTime / 60) / arr.length) * 10) / 10;
    let score;
    if (avgTime >= 5) {
      score = 5;
    }
    else if (avgTime >= 4 && avgTime < 5) {
      score = 4;
    }
    else if (avgTime >= 3.5 && avgTime < 4) {
      score = 3;
    }
    else if (avgTime < 3.5) {
      score = 2;
    }
    finalNoSalesDailySalesTime.push({ "salesRep": e, "totalTime": avgTime, "score": score })
  })
  // console.log(finalNoSalesDailySalesTime)

  let avgsalesreps = []

  let avgscore = []
  finalSalesRepData.map((e) => {
    if (e.salesRep == "Tanisha Mahajan" || e.salesRep == "Neelam Raikwar") avgsalesreps.push(e.salesRep)
  })
  finalCountArray.map((e) => {
    if (e.salesRep == "Tanisha Mahajan" || e.salesRep == "Neelam Raikwar") avgsalesreps.push(e.salesRep)
  })
  finalScoreTAT.map((e) => {
    if (e.salesRep == "Tanisha Mahajan" || e.salesRep == "Neelam Raikwar") avgsalesreps.push(e.salesRep)
  })
  finalDailySalesTime.map((e) => {
    if (e.salesRep == "Tanisha Mahajan" || e.salesRep == "Neelam Raikwar") avgsalesreps.push(e.salesRep)
  })
  finalNoSalesDailySalesTime.map((e) => {
    if (e.salesRep == "Tanisha Mahajan" || e.salesRep == "Neelam Raikwar") avgsalesreps.push(e.salesRep)
  })

  let unqAvgSalesRep = [...new Set(avgsalesreps)]
  // console.log(unqAvgSalesRep)
  unqAvgSalesRep.map((e) => {
    let avg = 0
    finalSalesRepData.map((j) => {
      if (e == j.salesRep) avg += j.score
    })
    finalCountArray.map((j) => {
      if (e == j.salesRep) avg += j.score
    })
    finalScoreTAT.map((j) => {
      if (e == j.salesRep) avg += j.score
    })
    finalDailySalesTime.map((j) => {
      if (e == j.salesRep) avg += j.score
    })
    finalNoSalesDailySalesTime.map((j) => {
      if (e == j.salesRep) avg += j.score
    })
    avgscore.push({ "salesRep": e, "score": avg / 5 })
  })

  return (
    <div className="bg-gradient-to-tr from-blue-500 via-purple-400 to-pink-300 min-h-screen">
      {(context != undefined) ?
        <div className="flex flex-col mx-auto py-10">
          {/* Heading */}
          <div className="mx-auto flex">
            <h1 className="text-3xl font-mono text-center border-b-2 w-max border-black">Sales Metrics Dashboard</h1>
          </div>
          {/* Buttons */}
          <div className="flex flex-col lg:flex-row gap-4 py-10 mx-auto">
            <button type="button" className="w-max p-2 text-sm bg-black text-white font-mono rounded-xl hover:shadow-xl hover:shadow-cyan-400" onClick={(e) => { if (query == false) { setQuery(true) } else { setQuery(false) } }}>Queries Score</button>
            <button type="button" className="w-max p-2 text-sm bg-black text-white font-mono rounded-xl hover:shadow-xl hover:shadow-cyan-400" onClick={(e) => { if (audit == false) { setAudit(true) } else { setAudit(false) } }}>Audit Score</button>
            <button type="button" className="w-max p-2 text-sm bg-black text-white font-mono rounded-xl hover:shadow-xl hover:shadow-cyan-400" onClick={(e) => { if (tat == false) { setTat(true) } else { setTat(false) } }}>Initiation Complete TAT</button>
            <button type="button" className="w-max p-2 text-sm bg-black text-white font-mono rounded-xl hover:shadow-xl hover:shadow-cyan-400" onClick={(e) => { if (sales == false) { setSales(true) } else { setSales(false) } }}>Time Tracking Project Management</button>
            <button type="button" className="w-max p-2 text-sm bg-black text-white font-mono rounded-xl hover:shadow-xl hover:shadow-cyan-400" onClick={(e) => { if (nonSales == false) { setNonSales(true) } else { setNonSales(false) } }}>Time Tracking Sales</button>
            <button type="button" className="text-sm border border-black rounded-lg p-2 hover:bg-black hover:text-white" onClick={() => window.location.reload(false)}>Reset App</button>
          </div>
          
          <div className="flex flex-row flex-wrap mx-auto px-16 pb-4 gap-16">
            {/* query score */}
            {query && <div className="flex flex-col gap-5 justify-center items-center">
              <div className="text-center font-mono text-lg border-b-2 border-black w-max">Queries Score</div>
              <div className="table-wrapper">
                <table className="fl-table">
                  <thead>
                    <tr>
                      <th>Sales Rep</th>
                      <th>Won</th>
                      <th>Query</th>
                      <th>Lead</th>
                      <th>Dropped</th>
                      <th>Total sum</th>
                      <th>Queries picked</th>
                      <th>Formula</th>
                      <th>Percentage</th>
                      <th>score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalSalesRepData.map((value) => (
                      <tr>
                        <td>{value.salesRep}</td>
                        <td>{value.Won}</td>
                        <td>{value.query}</td>
                        <td>{value.lead}</td>
                        <td>{value.dropped}</td>
                        <td>{value.sum}</td>
                        <td>{value.queries}</td>
                        <td>{" (( " + value.Won + " + " + value.query + " + " + value.lead + " + " + value.dropped + " ) / (" + value.queries + " / 2 )) X 100 " }</td>
                        <td>{value.percentage + " %"}</td>
                        <td>{value.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>}
            {/* audit score */}
            {audit && <div className="flex flex-col gap-5 justify-center items-center">
              <div className="text-center font-mono text-lg border-b-2 border-black">Audit Score</div>
              <div className="table-wrapper">
                <table className="fl-table">
                  <thead>
                    <tr>
                      <th>Sales Rep</th>
                      <th>Count</th>
                      <th>Score</th>
                      <th>Task</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalCountArray.map((value) => (
                      <tr>
                        <td>{value.salesRep}</td>
                        <td>{value.count}</td>
                        <td>{value.score}</td>
                        <td><div> {ReactHtmlParser(value.identifier)} </div></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>}
            {/* tat score */}
            {tat && <div className="flex flex-col gap-5 justify-center items-center">
              <div className="text-center font-mono text-lg border-b-2 border-black">Initiation Complete TAT</div>
              <div className="flex gap-5">
                <input id="date" onChange={(e) => setActualDate(e.target.value)} type="date" className="border-2 rounded-lg border-black p-3"></input>

              </div>
              <div className="table-wrapper">
                <table className="fl-table">
                  <thead>
                    <tr>
                      <th>Sales Rep</th>
                      <th>Count</th>
                      <th>Score</th>
                      <th>Identifier</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalScoreTAT.map((value) => (
                      <tr>
                        <td>{value.salesRep}</td>
                        <td>{value.count}</td>
                        <td>{value.score}</td>
                        <td>{value.identifier}</td>
                      </tr>

                    ))}
                  </tbody>
                </table>
              </div>
            </div>}
            {/* sales score */}
            {sales && <div className="flex flex-col gap-5 justify-center items-center">
              <div className="text-center font-mono text-lg border-b-2 border-black">Time Tracking Project Management</div>
              <div className="flex gap-5">
              </div>
              <div className="table-wrapper">
                <table className="fl-table">
                  <thead>
                    <tr>
                      <th>Sales Rep</th>
                      <th>time</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalDailySalesTime.map((value) => (
                      <tr>
                        <td>{value.salesRep}</td>
                        <td>{value.totalTime}</td>
                        <td>{value.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>}
            {/* project management score */}
            {nonSales && <div className="flex flex-col gap-5 justify-center items-center">
              <div className="text-center font-mono text-lg border-b-2 border-black">Time Tracking Sales</div>
              <div className="flex gap-5">
              </div>
              <div className="table-wrapper">
                <table className="fl-table">
                  <thead>
                    <tr>
                      <th>Sales Rep</th>
                      <th>time</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalNoSalesDailySalesTime.map((value) => (
                      <tr>
                        <td>{value.salesRep}</td>
                        <td>{value.totalTime}</td>
                        <td>{value.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>}
          </div >
          <div className="mx-auto w-1/5">
            <div className="py-2 items-center">
              <h1 className="font-mono text-lg text-center">Average Score</h1>
            </div>
            <div className="table-wrapper">
              <table className="fl-table">
                <thead>
                  <tr>
                    <th>Sales Rep</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {avgscore.map((value) => (
                    <tr>
                      <td>{value.salesRep}</td>
                      <td>{value.score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        :
        // loading screen
        <div className="flex flex-col font-mono flex justify-center gap-10 items-center w-screen h-screen">
         <div className="flex flex-row gap-10 items-center">
          <div className="flex flex-col items-center">
          <p className="flex text-xl font-semibold">We are getting your data</p>
          <p className="text-lg">Keep calm and wait for sometime</p>
          </div>
          <div role="status">
            <svg aria-hidden="true" class="w-8 h-8 mr-2 text-white animate-spin dark:text-white fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          </div>
          <button className="text-xl border border-black rounded-lg p-2 hover:bg-black hover:text-white" onClick={() => window.location.reload(false)}>Reset App</button>
        </div>
      }
    </div>
  );
};

export default App;
